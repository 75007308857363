import React from 'react';
import c from 'classnames';

import Container from 'components/ui/Container';
import { useI18next } from 'gatsby-plugin-react-i18next';
import CopyText from '../CopyText';
import TitleSection from '../ui/TitleSection';
import Icon, { IconNames } from '../Icon';
import Button from '../ui/Button';
import Link from 'gatsby-link';
import * as styles from './styles.module.scss';

type Props = {
  lawTexts: string[];
  title: string;
  serviceTitle: string;
  serviceTitleTwo?: string;
  law: IconNames;
};

const LawPage: React.FC<Props> = ({ lawTexts, law, serviceTitle, serviceTitleTwo, title }) => {
  const { t } = useI18next();

  return (
    <Container section>
      <Button
        primary
        onClick={() => {
          history.back();
        }}
        style={{ border: '1px solid black', color: 'black', background: 'transparent' }}
      >
        <div className="flex items-center text-black">
          <Icon name="Back" size="xs" additionalClass="mr-4" />
          {t('back')}
        </div>
      </Button>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-16 pl-4 pr-20">
        <div className="flex flex-col col-span-1 items-center justify-center md:mb-80">
          <div className="mobile-only">
            {/*<Icon name={law} additionalClass={styles.icon} />*/}
            <TitleSection
              title="&nbsp;"
              subtitle={<span className={styles.blueTitle}>{t(title)}</span>}
              noSeparator
              className="mb-16 mobile-only"
            />
          </div>
          <div className="flex-col no-mobile">
            <div className="flex flex-col items-center justify-center">
              <Icon name={law} additionalClass={styles.icon} />
              <Link to="/contact" className="no-mobile">
                <Button
                  primary
                  style={{
                    border: '2px solid #e45a11',
                    color: '#e45a11',
                    background: 'transparent',
                    fontWeight: 'bold',
                  }}
                >
                  {t('getConsultation')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-1 md:col-span-2">
          <TitleSection
            noMobile
            title="&nbsp;"
            subtitle={<span className={styles.blueTitle}>{t(title)}</span>}
            noSeparator
            className="mb-16 no-mobile text-justify"
          />
          <CopyText variant="copy-2" additionalClass="mb-16 text-justify">
            {t(serviceTitle)}
          </CopyText>
          {serviceTitleTwo && (
            <CopyText variant="copy-2" additionalClass="mb-16 text-justify">
              {t(serviceTitleTwo)}
            </CopyText>
          )}
          {lawTexts.map((service, key) => (
            <div key={key} className={c('py-10 flex w-full')}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  minWidth: 25,
                  minHeight: 25,
                  borderRadius: '100%',
                  border: '1px solid #312e91',
                  marginRight: '10px',
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="#312e91" viewBox="0 0 24 24">
                  <path d="M18 8.507L16.59 7 10 13.996l-2.58-2.729L6 12.764 10 17l8-8.493z" />
                </svg>
              </div>
              <CopyText variant="copy-4" additionalClass="my-auto text-justify">
                {t(service)}
              </CopyText>
            </div>
          ))}
          <Link to="/contact" className="mobile-only my-16 mx-auto">
            <Button
              primary
              style={{ border: '2px solid #e45a11', color: '#e45a11', background: 'transparent', fontWeight: 'bold' }}
            >
              {t('getConsultation')}
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default LawPage;
