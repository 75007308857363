import React from 'react';

import * as Styled from './styles';
import { StyledProps } from './styles';

interface Props extends StyledProps {
  title?: string;
  large?: boolean;
  subtitle?: any;
  className?: string;
  noSeparator?: boolean;
  style?: any;
  noMobile?: boolean;
}

const TitleSection: React.FC<Props> = ({
  noMobile,
  small,
  center,
  title,
  subtitle,
  className,
  large,
  noSeparator,
  style = {},
}) => {
  const Comp = noMobile ? Styled.TitleSectionNoMobile : Styled.TitleSection;
  return (
    <Comp className={className}>
      {subtitle && <Styled.SubTitle center={center}>{title}</Styled.SubTitle>}
      <Styled.Title center={center} large={large} small={small} {...style}>
        {subtitle}
      </Styled.Title>
      {!noSeparator && <Styled.Separator center={center} />}
    </Comp>
  );
};

export default TitleSection;
