import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawCivilPage: React.FC = () => (
  <Layout id="lawCivil">
    <SEO title="CivilLaw" />
      <LawPage
        law="LawCivil"
        title="fieldLawCivil"
        serviceTitle="fieldLawCivilTitle"
        lawTexts={[
          'fieldLawCivilOne',
          'fieldLawCivilTwo',
          'fieldLawCivilThree',
          'fieldLawCivilFour',
          'fieldLawCivilFive',
          'fieldLawCivilSix',
          'fieldLawCivilSeven',
          'fieldLawCivilEight',
          'fieldLawCivilNine',
        ]}
      />
  </Layout>
);

export default LawCivilPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
